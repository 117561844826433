<template>
  <div>
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <b-col class="mt-4 w-250">
            <basic-select
              :options="courses"
              name="course"
              label="Chọn khóa học có sẵn"
              :value="getNameOf('course', filter.courseId)"
              @update:value="selected($event, 'course', courses)"
              placeholder="-- Chọn khóa học -- "
              track-by="value"
              value-label="text"
              :hideSelected="true"
              :clear="true"
              :solid="false"
              changeValueByObject
            />
          </b-col>
          <b-col class="mt-4">
            <label for="createdtime">Thời gian diễn ra</label>
            <date-picker
              :range="true"
              :clearable="false"
              input-class="form-control"
              :default-value="Date.now()"
              type="date"
              format="DD/MM/YYYY"
              value-type="date"
              :disabled="true"
              v-model="filter.courseTime"
              @change="searchDate"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mt-4"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>

          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0">
              <b-row>
                <b-col cols="3">
                  <basic-input
                    label="Khách hàng"
                    placeholder="Nhập tên khách hàng"
                    name="name"
                    :value.sync="filter.name"
                  >
                  </basic-input>
                </b-col>
                <b-col cols="3">
                  <label>Trạng thái</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="status"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsStatus"
                    class="filter mb-0"
                    :value.sync="filter.status"
                    :clear="true"
                    @update:value="
                      selected($event, 'status', optionsStatus, null, 2)
                    "
                  />
                </b-col>
                <b-col cols="3">
                  <label>Follow OA</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="followOaFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.followOaFlag"
                    @update:value="
                      selected($event, 'followOaFlag', optionsFlag, null, 2)
                    "
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <label>Group Chung</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="joinGroupchatFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.joinGroupchatFlag"
                    @update:value="
                      selected(
                        $event,
                        'joinGroupchatFlag',
                        optionsFlag,
                        null,
                        2,
                      )
                    "
                  />
                </b-col>
                <b-col cols="3">
                  <label>Tải app</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="downloadAppFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.downloadAppFlag"
                    @update:value="
                      selected($event, 'downloadAppFlag', optionsFlag, null, 2)
                    "
                  />
                </b-col>
                <b-col cols="3">
                  <label>Group HC</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="joinHeathCouseFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.joinHeathCouseFlag"
                    @update:value="
                      selected(
                        $event,
                        'joinHeathCouseFlag',
                        optionsFlag,
                        null,
                        2,
                      )
                    "
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <label>Đăng nhập</label>
                  <basic-select
                    :disabled="true"
                    placeholder="Chọn trạng thái"
                    name="loginFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.loginFlag"
                    @update:value="
                      selected($event, 'loginFlag', optionsFlag, null, 2)
                    "
                  />
                </b-col>
                <b-col cols="3">
                  <label>Đặt lịch PVĐV</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="boardingBookingFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.boardingBookingFlag"
                    @update:value="
                      selected(
                        $event,
                        'boardingBookingFlag',
                        optionsFlag,
                        null,
                        2,
                      )
                    "
                  />
                </b-col>
                <b-col cols="3">
                  <label>Thực hiện PVĐV</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="boardingTestingFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.boardingTestingFlag"
                    @update:value="
                      selected(
                        $event,
                        'boardingTestingFlag',
                        optionsFlag,
                        null,
                        2,
                      )
                    "
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <label>Hoàn thành PVĐV</label>
                  <basic-select
                    placeholder="Chọn trạng thái"
                    name="boardingDoneFlag"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="text"
                    :options="optionsFlag"
                    class="filter mb-0"
                    :value.sync="filter.boardingDoneFlag"
                    @update:value="
                      selected($event, 'boardingDoneFlag', optionsFlag, null, 2)
                    "
                  />
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </template>
    </basic-subheader>
    <b-overlay>
      <template>
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :searchAction="false"
                  :selectAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td class="text-left mw-200">
                      {{ item.name }}
                    </td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_copy="false"
                        :show_view="false"
                        :show_edit="false"
                        :show_delete="false"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        @view="viewItem"
                      >
                        <b-dropdown-text
                          v-if="isWriteAllPermission || isWriteOwnerPermission"
                          tag="div"
                          class="navi-item cursor-pointer"
                        >
                          <a
                            class="navi-link cursor-pointer"
                            @click="deleteItem(item)"
                          >
                            <span
                              class="menu-icon svg-icon svg-icon-sm"
                              :class="'text-danger'"
                            >
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/power.svg"
                              />
                            </span>
                            <span
                              class="success navi-text"
                              :class="'text-danger'"
                            >
                              Danh sách tiềm năng
                            </span>
                          </a>
                        </b-dropdown-text>
                      </action-dropdown>
                    </td>
                    <td class="text-left mw-200">
                      <basic-select
                        placeholder="Chọn trạng thái"
                        name="status"
                        :solid="false"
                        changeValueByObject
                        :hideSelected="true"
                        trackBy="value"
                        valueLabel="text"
                        :options="optionsStatus"
                        class="filter mb-0"
                        :value="getNameOf('status', item.status)"
                        :clear="false"
                        @update:value="
                          selected($event, 'status', optionsStatus, item)
                        "
                        :disabled="true"
                      />
                    </td>
                    <td class="text-center">
                      {{ getCurrentWeek(item.startCourse) }}
                    </td>
                    <td class="text-center w-40" @click="handleCall(item)">
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Call/call-phone.svg"
                        />
                      </span>
                    </td>
                    <td class="text-center mw-200">
                      <basic-select
                        placeholder="Chọn coach"
                        name="coach"
                        :solid="false"
                        changeValueByObject
                        :hideSelected="true"
                        trackBy="value"
                        valueLabel="text"
                        :options="renderCoaches(item.courseId)"
                        class="filter mb-0"
                        :value="getNameOf('coach', item.coachId)"
                        :clear="false"
                        @update:value="
                          selected($event, 'coachId', coaches, item)
                        "
                      />
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.followOaFlag"
                          @change="
                            handleChangeCheckbox($event, item, 'followOaFlag')
                          "
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.joinGroupchatFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'joinGroupchatFlag',
                            )
                          "
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.downloadAppFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'downloadAppFlag',
                            )
                          "
                          :disabled="true"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.loginFlag"
                          @change="
                            handleChangeCheckbox($event, item, 'loginFlag')
                          "
                          :disabled="true"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.boardingBookingFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'boardingBookingFlag',
                            )
                          "
                          :disabled="true"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.boardingTestingFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'boardingTestingFlag',
                            )
                          "
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.boardingDoneFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'boardingDoneFlag',
                            )
                          "
                        ></v-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex justify-content-center">
                        <v-checkbox
                          color="#008479"
                          dense
                          class="checkbox-role"
                          v-model="item.joinHeathCourseFlag"
                          @change="
                            handleChangeCheckbox(
                              $event,
                              item,
                              'joinHeathCourseFlag',
                            )
                          "
                        ></v-checkbox>
                      </div>
                    </td>
                  </template>
                </template-table>
              </div>

              <b-modal
                v-model="isModalVisible"
                title="Thông tin đăng nhập"
                @hide="resetModal"
                :no-close-on-backdrop="true"
                size="l"
              >
                <div style="display: inline;font-size: 14px">
                  <p>
                    Gửi thông tin đăng nhập đến khách hàng:
                    <b>{{ this.selectedItemModalName }}</b>
                  </p>
                </div>
                <template v-slot:modal-footer>
                  <b-button variant="secondary" @click="isModalVisible = false">
                    Đóng
                  </b-button>
                  <b-button
                    class="btn btn-success ml-2"
                    @click="confirmSendSms"
                    :disabled="loading"
                  >
                    Xác nhận
                  </b-button>
                </template>
              </b-modal>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-overlay>
    <reason-question
      :targetItem="currentItem"
      :title="'Lý do chuyển người dùng thành khách hàng tiềm năng'"
      @load-data="loadData"
    />
    <call-client :targetItem="currentItem" @load-data="loadData" />
  </div>
</template>

<script>
const { mapState } = createNamespacedHelpers('customerReceives');
import { mapGetters, createNamespacedHelpers } from 'vuex';
import { TAG_TYPE } from '@/core/plugins/constants';
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
export default {
  components: {
    'date-picker': DatePicker,
    'reason-question': () => import('./ReasonModal.vue'),
    'call-client': () => import('./CallModal.vue'),
  },
  props: {
    searchParamsCourse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      groups: [
        {
          id: '1',
          name: 'ABC',
        },
      ],
      data: [],
      optionsFlag: [
        {
          text: 'Chưa đăng kí',
          value: false,
        },
        {
          text: 'Đã đăng kí',
          value: true,
        },
      ],
      optionsStatus: [
        {
          text: 'Đang tham gia',
          value: 1,
        },
        {
          text: 'Ngưng tham gia',
          value: 9,
        },
        {
          text: 'Kết thúc',
          value: 2,
        },
      ],
      selectedCoach: 0,
      coaches: [],
      followOaFlag: false,
      filter: {
        name: null,
        phoneNumber: null,
        contactSource: null,
        status: null,
        followOaFlag: null,
        joinGroupchatFlag: null,
        downloadAppFlag: null,
        loginFlag: null,
        boardingBookingFlag: null,
        boardingTestingFlag: null,
        boardingDoneFlag: null,
        joinHeathCouseFlag: null,
        accountId: null,
        patientId: null,
        coachId: null,
        courseName: null,
        courseId: null,
        courseTime: [
          moment().format('MM/DD/YYYY'),
          moment().format('MM/DD/YYYY'),
        ],
      },
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
          sortable: false,
        },
        {
          key: '',
          label: '',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '50%',
            'text-align': 'center',
          },
        },
        {
          key: 'week',
          label: 'Tuần',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'call',
          label: 'Gọi khách hàng',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'coach',
          label: 'Coach phụ trách',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'followOaFlag',
          label: 'Follow OA',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'joinGroupchatFlag',
          label: 'Group Chung',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'downloadAppFlag',
          label: 'Tải App',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'loginFlag',
          label: 'Đăng nhập',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'boardingBookingFlag',
          label: 'Đặt lịch PVĐV',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'boardingTestingFlag',
          label: 'Thực hiện PVĐV',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'boardingDoneFlag',
          label: 'Hoàn thành PVĐV',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'joinHeathCouseFlag',
          label: 'Group HC',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
      ],
      loading: false,
      selectedItems: [],
      currentItem: {},
      currenntCoach: {},
      notAllowUpdate: [],
      isModalVisible: false,
      selectedItemModalName: null,
      selectedItemModalPhoneNumber: null,
      disabled: false,
    };
  },
  computed: {
    ...mapState(['courses']),
    ...mapGetters(['currentUser']),
    isAdmin() {
      let roles = this.currentUser?.roles;
      if (roles && roles.length) {
        return roles.includes('Admin');
      } else {
        return false;
      }
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    searchParams() {
      const payload = this.removeEmpty({
        name: this.filter.name,
        status: this.filter.status,
        followOaFlag: this.filter.followOaFlag,
        joinGroupchatFlag: this.filter.joinGroupchatFlag,
        downloadAppFlag: this.filter.downloadAppFlag,
        loginFlag: this.filter.loginFlag,
        boardingBookingFlag: this.filter.boardingBookingFlag,
        boardingTestingFlag: this.filter.boardingTestingFlag,
        boardingDoneFlag: this.filter.boardingDoneFlag,
        joinHeathCouseFlag: this.filter.joinHeathCouseFlag,
        accountId: this.filter.accountId,
        patientId: this.filter.patientId,
        coachId: this.filter.coachId,
        courseName: this.filter.courseName,
        startCourse: moment(this.filter.courseTime[0]).format('MM/DD/YYYY'),
        endCourse: moment(this.filter.courseTime[1]).format('MM/DD/YYYY'),
        page: this.paging.page,
        size: this.paging.pageSize,
      });
      const params = new URLSearchParams();
      for (const property in payload) {
        params.append(property, payload[property]);
      }
      params.append('statuses', '1');
      params.append('statuses', '2');

      return params;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
    searchParamsCourse: {
      deep: true, // Watch for changes within the object
      immediate: true, // Trigger the handler when the component is created
      handler(value) {
        if (value != null) {
          this.filter.courseName = value.courseName;
        }
      },
    },
  },

  methods: {
    handleChangeCheckbox(event, item, key) {
      if (key === 'joinGroupchatFlag' && event === true) {
        this.isModalVisible = true;
        this.selectedItemModalName = item.name;
        this.selectedItemModalPhoneNumber = item.phoneNumber;
      }
      // if (
      //   key === 'downloadAppFlag' ||
      //   key === 'loginFlag' ||
      //   key === 'boardingBookingFlag'
      // ) {
      //   const matchedAccountFlagBooking =
      //     this.notAllowUpdate.accountUpdateFlagsBooking.find(
      //       (booking) => booking.phoneNumber === item.phoneNumber,
      //     );
      //   const matchedAccountFlag = this.notAllowUpdate.accountUpdateFlags.find(
      //     (flag) => flag.phoneNumber === item.phoneNumber,
      //   );

      //   if (matchedAccountFlagBooking != null || matchedAccountFlag != null) {
      //     this.$store.commit('context/setLoading', false);
      //     return this.$toastr.w({
      //       title: 'Chú ý !',
      //       msg: 'Trường này được hệ thống tự động cập nhật',
      //     });
      //   }
      // }

      this.changeStatus({
        ...item,
        [key]: event,
      });
    },
    renderCoaches(courseId) {
      let rs = this.coaches.filter(
        (coach) => coach.courses && coach.courses.includes(courseId),
      );
      return rs;
    },
    searchDate() {
      if (
        this.filter.courseTime[0] == null &&
        this.filter.courseTime[1] == null
      ) {
        this.filter.courseTime = [
          moment().format('MM/DD/YYYY'),
          moment().format('MM/DD/YYYY'),
        ];
      }
      this.resetPaging();
    },
    getCurrentWeek(startDate) {
      // Kiểm tra nếu startDate là null, undefined hoặc chuỗi rỗng
      if (!startDate) return 'Chưa xác định';
      const start = new Date(startDate);
      const today = new Date();

      // Tính số ngày giữa hai ngày
      const oneDay = 24 * 60 * 60 * 1000; // Số milliseconds trong một ngày
      const diffDays = Math.floor((today - start) / oneDay);

      // Tính số tuần
      const currentWeek = Math.floor(diffDays / 7) + 1;

      return currentWeek;
    },
    formatCoursePeriod(startCourse, endCourse) {
      const formatDate = (date) => {
        if (!date) return null;
        const dateObj = new Date(date);
        if (isNaN(dateObj)) return null;
        return dateObj.toISOString().split('T')[0];
      };

      const formattedStart = formatDate(startCourse);
      const formattedEnd = formatDate(endCourse);

      if (formattedStart && formattedEnd) {
        return `${formattedStart} - ${formattedEnd}`;
      } else if (formattedStart) {
        return `${formattedStart} - Chưa xác định`;
      } else if (formattedEnd) {
        return `Chưa xác định - ${formattedEnd}`;
      } else {
        return 'Chưa xác định';
      }
    },
    handleCall(item) {
      this.currentItem = item;
      this.$bvModal.show('call-modal');
    },
    async searchRequest() {
      this.loadData();
      this.UpdateAppFlags();
      document.body.click();
    },
    async UpdateAppFlags() {
      await this.$api.get('Account/UpdateAppFlags');
    },
    async resetRequest() {
      this.filter.name = null;
      this.filter.phoneNumber = null;
      this.filter.contactSource = null;
      this.filter.status = null;
      this.filter.followOaFlag = null;
      this.filter.joinGroupchatFlag = null;
      this.filter.downloadAppFlag = null;
      this.filter.loginFlag = null;
      this.filter.boardingBookingFlag = null;
      this.filter.boardingTestingFlag = null;
      this.filter.boardingDoneFlag = null;
      this.filter.joinHeathCouseFlag = null;
      this.filter.accountId = null;
      this.filter.patientId = null;
      this.filter.coachId = null;
      this.filter.startCourse = null;
      this.filter.endCourse = null;
      this.filter.courseName = null;
      this.loadData();
      this.UpdateAppFlags();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      this.loadData();
    },
    selected(value, formProperty, optionsArr, item = null, type = 1) {
      console.log({
        value,
        formProperty,
        optionsArr,
      });

      if (formProperty == 'course') {
        this.filter.courseName = value?.text;
        this.resetPaging();
        if (Boolean(value?.startDatetime) && Boolean(value?.endDatetime)) {
          this.filter.courseTime = [
            moment(value?.startDatetime * 1000).toDate(),
            moment(value?.endDatetime * 1000).toDate(),
          ];
        } else {
          this.filter.courseTime = [
            moment().format('MM/DD/YYYY'),
            moment().format('MM/DD/YYYY'),
          ];
        }
        return;
      }

      const v = value
        ? optionsArr.find((el) => el.value === value?.value)
        : null;
      if (type == 1 && v != null && item != null) {
        this.changeStatus({
          ...item,
          [formProperty]: v.value,
        });
        return;
      }

      if (type == 2 && value != null) {
        this.filter[formProperty] = value?.value || false;
      }
    },
    viewItem(item) {
      this.$router.push({
        name: 'customer_receives_view',
        params: {
          id: item.id,
        },
      });
    },
    select(params) {
      this.$emit('select', params);
    },
    editItem(item) {
      this.$router.push({
        name: 'customer_receives_edit',
        params: {
          id: item.id,
          form_type: 'edit',
        },
      });
    },
    createItem() {
      this.$router.push({
        name: 'customer_receives_edit',
        params: {
          form_type: 'create',
        },
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        this.veeFields[ref].dirty &&
        this.veeFields[ref].validated
      ) {
        return this.veeFields[ref].valid;
      }
      return null;
    },
    importItems() {},
    deleteItem(item) {
      this.currentItem = item;
      this.$bvModal.show('reason-modal');
    },
    copyItem(item) {
      this.$router.push({
        name: 'customer_receives_detail',
        params: {
          form_type: 'copy',
          id: item.id,
        },
      });
    },
    getNameOf(key, value) {
      switch (key) {
        case 'coach':
          return this?.coaches?.find((item) => item.value == value) ?? null;
        case 'course':
          return this.courses?.find((item) => item.value == value) ?? null;
        default:
          return (
            this.optionsStatus?.find((item) => item.value == value) ?? null
          );
      }
    },
    getStatusOf(key, item) {
      return !!item[key];
    },

    async changeStatus(item) {
      this.$store.commit('context/setLoading', true);
      // Create a new FormData object
      const formData = new FormData();
      const keyBoolean = [
        'followOaFlag',
        'joinGroupchatFlag',
        'downloadAppFlag',
        'loginFlag',
        'boardingBookingFlag',
        'boardingTestingFlag',
        'boardingDoneFlag',
        'joinHeathCourseFlag',
        'isDeleted',
      ];
      // Append properties of the item object to formData

      Object.keys(item).forEach((key) => {
        if (!['callCount', 'gender', 'timeAccepted'].includes(key)) {
          formData.append(
            key,
            keyBoolean.includes(key) ? !!item[key] : item[key] || '',
          );
        }
      });
      // Send the FormData using the PUT method

      try {
        await this.$api.put(`/CustomerReceives/${item.id}`, formData);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } catch (error) {
        this.$store.commit('context/setLoading', false);
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
      this.loadData();
    },
    async confirmSendSms() {
      this.loading = true;
      const formData = {
        PhoneNumber: this.selectedItemModalPhoneNumber,
      };

      try {
        await this.$api.post('CustomerReceives/UserLoginInformation', formData);
      } catch (error) {
        console.log('Send Sms Error');
        this.loading = false;
      } finally {
        this.loading = false;
        this.isModalVisible = false;
        this.$toastr.s({
          title: 'Gửi thành công',
          msg: 'Đã gửi thông tin đến người dùng',
        });
      }
    },

    async loadData() {
      try {
        this.$store.commit('context/setLoading', true);
        ///      this.notAllowUpdate = responeAutoFlag;

        const { data, meta } = await this.$api.get('CustomerReceives', {
          params: this.searchParams,
        });
        const filteredData =
          data?.filter(
            (el) => (el.status === 1 || el.status == 2) && el.isDeleted == 0,
          ) || [];

        this.data =
          filteredData?.map((el) => {
            return {
              ...el,
            };
          }) || [];
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    getDataHospital(tags) {
      if (!tags || !tags.length) return null;
      const agencies = tags.filter((el) => el.tag.type === TAG_TYPE.AGENCY);
      return agencies.map((el) => el.tag.name).join(', ');
    },
    getStatus(status) {
      if (status == 1) return 'active';
      if (status == 2) return 'warning-color';
      return 'inactive';
    },
    searchDebounce(value) {
      this.filter.courseName = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    getSearchDataPrepare() {
      this.$api
        .get('CustomerReceives/PrepareSearchFormItem')
        .then(({ data }) => {
          const { coaches } = data;
          this.coaches = coaches?.map((c) => {
            return {
              text: c?.coachName,
              value: c?.coachId,
              courses: c?.courseIds,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        });
    },
  },
  async handleExport() {
    this.$store.commit('context/setLoading', true);
    const params = {
      downloadFilters: this.selectedItems?.map((el) => ({
        id: el.id,
      })),
    };
    this.$api
      .post('/Product/Product-Download', params, {
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${this.getFileName(res.headers['content-disposition'])}`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Xuất kết quả lỗi',
        });
      })
      .finally(() => {
        this.$store.commit('context/setLoading', false);
      });
  },
  async created() {
    await Promise.all([
      this.loadData(),
      this.UpdateAppFlags(),
      this.getSearchDataPrepare(),
    ]);
  },
};
</script>

<style lang="scss" scoped>
.w-250 {
  width: 250px;
}
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #00c7f9;
  }

  &.warning-color {
    background-color: #ff5756;
  }
}

.customer-list-page__body__tabs::v-deep {
  margin: 0 -2rem -2rem;

  a.nav-link {
    border: 0;

    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }

  li.nav-item {
    a.nav-link:hover {
      color: #333;
    }
  }

  .tab-content {
    display: none;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}

.search-input {
  min-width: 220px;
}

.mw-400 {
  min-width: 400px;
}

.mw-200 {
  min-width: 200px;
}

.mw-250 {
  min-width: 250px;
}

.w-40 {
  width: 40px;
}

.mw-157 {
  width: 157px;
}
</style>
